import { IdTokenClaims } from "@/models/authentication";
import { User } from "@/models/user";
import { AccountInfo } from "@azure/msal-browser";
import { Ref, ref, watch } from "vue";
import { useMsal } from "./useMsal";

export function useIsAuthenticated(): { isAuthenticated: Ref<boolean>, user: Ref<User|null>} {
    const { accounts } = useMsal();
    const isAuthenticated = ref(accounts.value.length > 0);
    
    let user: Ref<User|null> = ref<null>(null);

    if(isAuthenticated.value) {
        user = ref(getUser(accounts));
    }

    watch(accounts, () => {
        isAuthenticated.value = accounts.value.length > 0;
        user.value = isAuthenticated.value ? getUser(accounts) : null;
    });

    return {
        isAuthenticated,
        user
    };
}

function getUser(accounts: Ref<AccountInfo[]>): User {
    const details: IdTokenClaims = accounts.value[0].idTokenClaims as IdTokenClaims;
    return {
        displayName: (accounts.value[0]?.name || accounts.value[0]?.username) ?? "",
        firstName: details?.given_name ?? "",
        lastName: details?.family_name ?? "",
        phone: details?.extension_PhoneNumber ?? "",
        email: details?.emails?.[0] ?? "",
        country: details?.country ?? "",
    }
}