import { accountExists } from "@/services/user.service";
import { Validation } from "@vuelidate/core";
import { email, helpers } from "@vuelidate/validators";
import { Ref } from "vue";

export const emailUnique = <ComponentInstance = unknown>(isAuthenticated: Ref<boolean> ) => {
  return async (
    value: string,
    _: ComponentInstance,
    { v$ }: { v$: Validation }
  ): Promise<boolean> => {

    if (isAuthenticated.value) {
      return true;
    }

    const invalidEmail =
      v$.$errors.filter((e) => e.$validator === "email").length > 0;

    if (!value || invalidEmail) {
      return false;
    }
    return await accountExists(value);
  };
};

export const toastSettings = (description?: string) => ({
  group: "submission-error",
  severity: "error",
  detail: description ?? "We are sorry - something went wrong",
  life: 10000,
});
